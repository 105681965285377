<template>
  <div>
    <v-row>
      <v-col>
      <!-- Recent purchases -->

      </v-col>
      <v-col>
      <!-- Completed Downloadables -->

      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { gql } from 'apollo-boost'
  export default {
    data() {
      return {
        
      }
    },
    apollo: {
      me: {
        query: gql`query {
          me {
            id
          }
        }`
      },
      orders: {
        query: gql`query($limit: Int! $skip: Int! $query: JSON!) {
          orders(limit: $limit, skip: $skip, query: $query) {
            id
            products {
              id
              title
              featurePicture
              category
              tags {
                id
                name
                type
              }
            }
            createdAt
          }
        }`,
        variables() {
          const self = this
          return {
            limit: 9999,
            skip: 0,
            query: {
              createdBy: self.me.id
            }
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>